// extracted by mini-css-extract-plugin
export var container = "Profil-module--container--35d19";
export var containerCenter = "Profil-module--containerCenter--aa66c";
export var containerLeft = "Profil-module--containerLeft--19082";
export var containerRight = "Profil-module--containerRight--92b00";
export var informations = "Profil-module--informations--b55b9";
export var job = "Profil-module--job--4223c";
export var mail = "Profil-module--mail--002e9";
export var name = "Profil-module--name--87dd6";
export var phone = "Profil-module--phone--80bde";
export var profilImage = "Profil-module--profilImage--4cf36";
export var smallContainerCenter = "Profil-module--smallContainerCenter--8e128";
export var wideImage = "Profil-module--wideImage--798e1";
export var wideImageContainer = "Profil-module--wideImageContainer--85d55";