import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { ContactPageContext } from "../../helpers/pagesContext";
import { ContactForm } from "./components/ContactForm/ContactForm";
import { Hero } from "./components/Hero/Hero";
import StoryTelling from "./components/StoryTelling/StoryTelling";
import * as styles from "./Contact.module.css";

export default function Contact() {
  const { footer, canopy, adresseOne, adresseTwo, node_locale } =
    useContext(ContactPageContext);

  return (
    <>
      <Hero />
      <StoryTelling />
      <ContactForm />
      <section className={styles.contactMapContainer}>
        <div className={styles.contactMap}>
          <div className={styles.contactMapHead}>
            <h3>{canopy}</h3>
            <p>
              {adresseOne}
              <br />
              {adresseTwo}
            </p>
          </div>
          <div className={styles.map}>
            <iframe
              src={`https://snazzymaps.com/embed/${process.env.GATSBY_SNAZZY_MAPS_ID}`}
              className={styles.contactMapIframe}
            ></iframe>
            <div className={styles.overlay} />
          </div>
        </div>
      </section>
      <div
        style={{
          background: `url(${footer.file.url}) top center / cover no-repeat`,
        }}
        className={styles.footer}
      >
        <Footer background="transparent" lang={node_locale} />
      </div>
    </>
  );
}
