import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./Profil.module.css";

export default function Profil({ profile, side, wide }) {
  const smallScreen = useMediaQuery("(max-width:767.98px)");
  const { name, job, phone, mail, gatsbyImageData } = profile;
  return wide ? (
    <div>
      <div className={styles.wideImageContainer}>
        <div className={styles.wideImage}>
          <GatsbyImage image={gatsbyImageData} alt="logo" />
        </div>
        <div>
          <div>
            <div className={styles.name}>{name}</div>
            <div className={styles.job}>{job}</div>
          </div>
          <div>
            <div className={styles.phone}>
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
            <div className={styles.mail}>
              <a href={`mailto:${mail}`}>{mail}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        styles.container,
        side === "left"
          ? styles.containerLeft
          : side === "right"
          ? styles.containerRight
          : side === "center" && smallScreen
          ? styles.smallContainerCenter
          : styles.containerCenter
      )}
    >
      {!smallScreen && (
        <div className={styles.profilImage}>
          <GatsbyImage image={gatsbyImageData} alt="logo" />
        </div>
      )}
      <div className={styles.informations}>
        <div>
          {smallScreen && (
            <div>
              <GatsbyImage image={gatsbyImageData} alt="logo" />
            </div>
          )}
          <div>
            <div className={styles.name}>{name}</div>
            <div className={styles.job}>{job}</div>
          </div>
          <div>
            <div className={styles.phone}>
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
            <div className={styles.mail}>
              <a href={`mailto:${mail}`}>{mail}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
