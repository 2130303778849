// extracted by mini-css-extract-plugin
export var borderButton = "StoryTelling-module--borderButton--11f43";
export var container = "StoryTelling-module--container--53904";
export var email = "StoryTelling-module--email--8a336";
export var story = "StoryTelling-module--story--71d44";
export var storyContainer = "StoryTelling-module--storyContainer--c2534";
export var storyContainerCEO = "StoryTelling-module--storyContainerCEO--0c7b4";
export var storyHeader = "StoryTelling-module--storyHeader--dc59a";
export var storyHeader__catchphrase = "StoryTelling-module--storyHeader__catchphrase--47b22";
export var storyHeader__title = "StoryTelling-module--storyHeader__title--6fefd";
export var storybody = "StoryTelling-module--storybody--c81ea";
export var storybodyCentered = "StoryTelling-module--storybodyCentered--1d2db";
export var storybodyLeft = "StoryTelling-module--storybodyLeft--15589";
export var storybodyWide = "StoryTelling-module--storybodyWide--3a443";