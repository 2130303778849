import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { ContactPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const medium = useMediaQuery("(min-width:991.98px)");
  const { hero, listen, echange } = useContext(ContactPageContext);

  return (
    <>
      <section
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.16) 100%, rgba(0, 0, 0, 0.16) 0px) 0% 0% , url(${hero.file.url}) 7% center/cover no-repeat`,
        }}
        className={styles.root}
      >
        <div className={styles.container}>
          <div className={styles.board}>
            {medium ? (
              <>
                <SlideTransition thresholds={0} delay={0.8}>
                  <div className={styles.firstLine}>{listen}</div>
                </SlideTransition>
                <SlideTransition thresholds={0} delay={1}>
                  <h1 className={styles.secondLine}>{echange}</h1>
                </SlideTransition>
              </>
            ) : (
              <SlideTransition thresholds={200} delay={0.8} duration={2.8}>
                <div className={styles.firstLine}>{listen}</div>
                <h1>
                  <div className={styles.secondLine}>{echange}</div>
                </h1>
              </SlideTransition>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
