import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";

import { BorderButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { ContactPageContext } from "../../../../helpers/pagesContext";
import MatchYourBusiness from "../../../MatchYourBusiness/MatchYourBusiness";
import MatchYourResume from "../../../MatchYourResume/MatchYourResume";
import Profil from "./components/Profil";
import * as styles from "./StoryTelling.module.css";

export default function StoryTelling() {
  const [openMatchYourResume, setOpenMatchYourResume] = useState(false);
  const [openMatchYourBusiness, setOpenMatchYourBusiness] = useState(false);
  const cv = "cv";
  const business = "besoin";

  const ref = useRef();

  const {
    contactProfilGroup,
    node_locale,
    location: { state, search },
  } = useContext(ContactPageContext);

  useEffect(() => {
    if (state) {
      if (state.RH) {
        setTimeout(() => {
          ref.current?.scrollIntoView();
        }, 200);
      }
      if (state.CV) setOpenMatchYourResume(true);
    }
    if (search) {
      if (search === `?${cv}`) setOpenMatchYourResume(true);
      if (search === `?${business}`) setOpenMatchYourBusiness(true);
    }
  }, []);

  const handleCloseMatchYourResume = () => {
    window.history.pushState("", "", window.location.pathname);
    setOpenMatchYourResume(false);
  };

  const handleCloseMatchYourBusiness = () => {
    window.history.pushState("", "", window.location.pathname);
    setOpenMatchYourBusiness(false);
  };

  const openBusiness = () => {
    window.history.pushState("", "", window.location.pathname + "?" + business);
    setOpenMatchYourBusiness(true);
  };

  const openResume = () => {
    window.history.pushState("", "", window.location.pathname + "?" + cv);
    setOpenMatchYourResume(true);
  };

  return (
    <>
      <section className={styles.container}>
        {contactProfilGroup.map((profilGroupe, index) => {
          return (
            <div
              ref={index === 1 ? ref : null}
              className={styles.story}
              key={profilGroupe.id}
            >
              <div className={styles.storyHeader}>
                <h2 className={styles.storyHeader__title}>
                  {profilGroupe.title}
                </h2>
                <div className={styles.storyHeader__catchphrase}>
                  {profilGroupe.catchphrase}
                </div>
              </div>
              <div
                className={clsx(
                  styles.storyContainer,
                  index === 1 && styles.storyContainerCEO,
                )}
              >
                <SlideTransition delay={0.2}>
                  <div
                    className={clsx(
                      styles.storybody,
                      profilGroupe.contactProfile.length === 1 &&
                        styles.storybodyCentered,
                      profilGroupe.contactProfile.length === 3 &&
                        styles.storybodyWide,
                    )}
                  >
                    {profilGroupe.contactProfile.map(
                      (
                        {
                          id,
                          name,
                          job,
                          phone,
                          mail,
                          profile: { gatsbyImageData },
                        },
                        index,
                      ) => {
                        return profilGroupe.contactProfile.length > 1 ? (
                          <div
                            key={id}
                            className={clsx(
                              index === 0 &&
                                profilGroupe.contactProfile.length !== 3 &&
                                styles.storybodyLeft,
                            )}
                          >
                            <Profil
                              profile={{
                                name,
                                job,
                                phone,
                                mail,
                                gatsbyImageData,
                              }}
                              side={index === 0 ? "left" : "right"}
                              wide={profilGroupe.contactProfile.length === 3}
                            />
                          </div>
                        ) : (
                          <div key={id}>
                            <Profil
                              profile={{
                                name,
                                job,
                                phone,
                                mail,
                                gatsbyImageData,
                              }}
                              side={"center"}
                            />
                          </div>
                        );
                      },
                    )}
                  </div>
                </SlideTransition>
                <SlideTransition delay={0.4}>
                  <div className={styles.borderButton}>
                    <BorderButton
                      label={profilGroupe.buttonText}
                      onClick={() =>
                        index === 1 ? openBusiness() : openResume()
                      }
                      fill="#fff"
                      wide={true}
                    />
                  </div>
                </SlideTransition>
                <SlideTransition delay={0.6}>
                  <div className={styles.email}>
                    <a href={`mailto:${profilGroupe.email}`}>
                      {profilGroupe.email}
                    </a>
                  </div>
                </SlideTransition>
              </div>
            </div>
          );
        })}
      </section>
      <MatchYourResume
        open={openMatchYourResume}
        handleClose={handleCloseMatchYourResume}
        lang={node_locale}
      />
      <MatchYourBusiness
        open={openMatchYourBusiness}
        handleClose={handleCloseMatchYourBusiness}
        lang={node_locale}
      />
    </>
  );
}
