import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { ContactPageContext } from "../helpers/pagesContext";
import { CONTACT } from "../helpers/route";
import ContactScene from "../scenes/Contact/Contact";

export default function Contact({ data, location }) {
  return (
    <Layout currentRoute={CONTACT} data={data.contentfulMenuLabel}>
      <Seo
        title="Contact"
        description="Envie de nous rejoindre, faire matcher votre CV, votre entreprise à besoin de notre expertise, une autre raison pour nous contacter ? N'hésitez pas, un membre de Zenity sera là pour vous répondre !"
      />
      <ContactPageContext.Provider
        value={{ ...data.contentfulContact, location }}
      >
        <ContactScene />
      </ContactPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulContact(node_locale: { eq: $locale }) {
      adresseOne
      adresseTwo
      anotherReason
      echange
      email
      firstname
      lastname
      listen
      mail
      mailError
      phoneFormatError
      mailRequired
      message
      messageRequired
      node_locale
      phone
      phoneForm
      reactivity
      spaceId
      title
      wellSendt
      canopy
      send
      hero {
        file {
          url
        }
      }
      footer {
        file {
          url
        }
      }
      contactProfilGroup {
        contactProfile {
          job
          mail
          name
          phone
          profile {
            gatsbyImageData(placeholder: BLURRED)
          }
          id
        }
        buttonText
        catchphrase
        email
        title
        id
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
