import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import { ContactPageContext } from "../../../../helpers/pagesContext";
import { FormInputText } from "./components/FormInputText/FormInputText";
import * as styles from "./ContactForm.module.css";

export const ContactForm = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    },
  });
  const {
    wellSendt,
    anotherReason,
    reactivity,
    mail,
    phone,
    firstname,
    lastname,
    phoneForm,
    email,
    message,
    mailRequired,
    messageRequired,
    mailError,
    phoneFormatError,
    send,
  } = useContext(ContactPageContext);
  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      const result = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
      await result.json();
      reset();
      enqueueSnackbar(wellSendt, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        action: (key) => (
          <>
            <CloseIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </>
        ),
      });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log(error);
    }
  };

  const sx = {
    flex: 1,
    marginRight: "30px",
  };

  return (
    <section className={styles.contact}>
      <div className={styles.contactUs}>
        <div className={styles.contactUsMsg}>
          <div className={styles.contactUsMsgHead}>
            <h2>{anotherReason}</h2>
            <p>{reactivity}</p>
          </div>
          <div className={styles.contactUsMsgContact}>
            <a href={`mailto:${mail}`}>
              <EmailIcon />
              {mail}
            </a>
            <a href={`tel:${phone.replaceAll(" ", "")}`}>
              <LocalPhoneIcon />
              {phone}
            </a>
          </div>
        </div>
        <div className={styles.contactUsForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <FormInputText
                label={firstname}
                name="firstName"
                control={control}
                error={errors.firstName}
                sx={sx}
              />
              <FormInputText
                label={lastname}
                name="lastName"
                control={control}
                error={errors.lastName}
                sx={sx}
              />
            </div>
            <div>
              <FormInputText
                label={phoneForm}
                name="phone"
                control={control}
                error={errors.phone}
                sx={sx}
                rules={{
                  pattern: {
                    value: /^0[1-9]{1}(\d{2}){4}$/i,
                    message: phoneFormatError,
                  },
                }}
              />
              <FormInputText
                label={email}
                name="email"
                control={control}
                error={errors.email}
                rules={{
                  required: mailRequired,
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                    message: mailError,
                  },
                }}
                sx={sx}
              />
            </div>
            <div>
              <FormInputText
                label={message}
                name="message"
                control={control}
                error={errors.message}
                rules={{
                  required: messageRequired,
                  pattern: {
                    value: /.*[^ ].*/i,
                    message: messageRequired,
                  },
                }}
                multiline
                rows={6}
                variant="outlined"
                sx={sx}
              />
            </div>
            <input
              className={styles.contactUsFormSubmit}
              type="submit"
              value={send}
            />
          </form>
        </div>
      </div>
    </section>
  );
};
